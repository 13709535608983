@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css?family=Mina&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

a {
  text-decoration: none;
  cursor: none;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

body {
  width: "100%";
  min-height: 100vh;
  background-color: rgb(0, 0, 0);
}

img {
  user-select: none; /* Prevents selection of images */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
  pointer-events: none; /* Prevents interaction (like dragging) */
}

/* LANDING PAGE */
.landpage {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* HOME PAGE */

.homepage {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.marketpage {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.lowerdiv {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.lowerdivx {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.namezeroyxs {
  opacity: 0;
  position: absolute;
}

.allpages {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
}

.header {
  position: fixed;
  width: 100%;
  height: 13vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(14px);
  padding-left: 12px;
  padding-right: 12px;
  z-index: 9000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.029);
}

.cuts {
  position: absolute;
  width: 100px;
  height: 90px;
  background-color: rgb(0, 0, 0);
  rotate: 45deg;
  right: -54px;
  bottom: -50px;
}

.lineerases {
  position: absolute;
  width: 22px;
  height: 194px;
  top: 0px;
  right: 0px;
  border-right: 1px solid rgba(76, 254, 0, 0.273);
  background-color: rgba(0, 0, 0, 0);
}

.eventsx {
  width: 360px;
  height: 200px;
  opacity: 0;
}

.menubar {
  width: auto;
  position: relative;
}

.arrows {
  position: absolute;
  width: 14px;
  right: 8px;
}

.lineerase {
  position: absolute;
  width: 12px;
  height: 4px;
  top: 17px;
  right: 1px;
  background-color: rgb(5, 5, 5);
}

.logo img {
  width: 80px;
  height: auto;
}

.allmenu {
  width: 350px;
  min-height: 20vh;
  position: absolute;
  left: 50px;
  top: 5px;
  padding: 29px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.608);
  /* border-right: 1px solid rgba(255, 255, 255, 0.601); */
  background-color: rgba(255, 255, 255, 0.042);
  transition:
    opacity 2s ease-out,
    transform 0.5s ease-out;
  transform: translateY(-10px);
  overflow: hidden;
  z-index: 900008999;
}
.allmenux {
  width: 350px;
  min-height: 20vh;
  position: absolute;
  left: 50px;
  top: 5px;
  padding: 29px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid rgba(155, 255, 15, 0.453);
  /* border-right: 1px solid rgba(255, 255, 255, 0.601); */
  background-color: rgba(0, 0, 0, 0.629);
  transition:
    opacity 2s ease-out,
    transform 0.5s ease-out;
  transform: translateY(-10px);
  overflow: hidden;
  z-index: 9000;
  border-bottom-right-radius: 50px;
}
.menux {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 10px;
  position: relative;
  height: 30px;
  background-color: white;
}

.menux p {
  color: white;
  font-size: 0.9em;
  letter-spacing: 0.9px;
  cursor: pointer;
}

.menux:hover p {
  font-size: 0.9em;
  color: rgb(64, 186, 3);
  letter-spacing: 0.9px;
  cursor: pointer;
}
.menux:hover .arrows {
  opacity: 0;
  visibility: hidden;
}

.menuxs:hover .arrows {
  opacity: 0;
  visibility: hidden;
}

.menuxs:hover p {
  font-size: 0.9em;
  color: rgb(64, 186, 3);
  letter-spacing: 0.9px;
  cursor: pointer;
}

.menux:hover .circle {
  font-size: 0.7em;
  background-color: rgb(64, 186, 3);
  letter-spacing: 0.9px;
  cursor: pointer;
}

.menuxs:hover .circles {
  font-size: 0.7em;
  background-color: rgb(64, 186, 3);
  letter-spacing: 0.9px;
  cursor: pointer;
}

.popup {
  visibility: hidden;
  position: absolute;
  top: 8px;
  right: 4px;
  width: 22px;
  height: 22px;
  border: 2px solid rgb(113, 255, 47);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.9s ease-in-out;
}

.popups {
  visibility: hidden;
  position: absolute;
  top: 8px;
  right: 4px;
  width: 32px;
  height: 32px;
  border: 2px solid rgb(113, 255, 47);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.9s ease-in-out;
}

.menux:hover .popup {
  visibility: visible;
  opacity: 1;
}

.menuxs:hover .popup {
  visibility: visible;
  opacity: 1;
}
.circular {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: greenyellow;
}

.circulars {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: greenyellow;
}

.menuxs {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 10px;
  position: relative;background-color: white;
}

.menuxs p {
  color: white;
  font-size: 0.9em;
  letter-spacing: 0.9px;
  cursor: pointer;
}
.menuimg {
  /* cursor: pointer; */
  width: 25px;
  pointer-events: auto;
}

.circle {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}

.circles {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}

.contents {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 23%;
  flex-direction: column;
  /* margin-left: 14%;
  margin-right: 55px; */
}

.welcomes {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0);
}

.welcomes .toinno {
  width: 690px;
  /* height: 370px; */
  margin-top: -35px;
}

.welcomes .welco {
  width: 900px;
  /* height: 370px; */
  opacity: 0;
  position: absolute;
}

.innovideo {
  position: absolute;
}

.innovations {
  width: 500px;
}

.innovationsx {
  width: 300px;
  position: absolute;
  top: 10%;
  left: -7.6%;
}

.innovationsxa {
  width: 300px;
  position: absolute;
  bottom: 2%;
  right: -7%;
  z-index: 1;
}

.learnbtn {
  width: 30%;
  height: 50px;
  border: none;
  background-color: rgba(0, 255, 166, 0.032);
  color: rgb(173, 252, 201);
  border-top: 1px solid rgb(34, 255, 111);
  font-weight: 444;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 29px;
  left: 35%;
  backdrop-filter: blur(2px);
}

.learnbtn:hover {
  width: 170px;
  height: 45px;
  background-color: rgb(81, 248, 81);
  color: black;
  border-radius: 6px;
  border: none;
  /* margin-top: 94px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
}

/* ABOUT US */

.aboutcontents {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  height: 50vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.haveacool {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  position: relative;
}

.coolimg {
  width: 340px;
  height: auto;
  border-bottom: 2px solid rgba(255, 255, 255, 0.438);
}

.contaxts {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 40%;
  height: 65vh;
  border: 1px solid rgba(255, 255, 255, 0.295);
  padding: 43px;
  border-radius: 7px;
  backdrop-filter: blur(20px);
  position: relative;
}

.getint {
  width: 26%;
  margin-left: -34px;
  border-radius: 35px;
  height: 40px;
  position: absolute;
  bottom: 13px;
  right: 40px;
}

.getintx {
  width: 100%;
  background-color: rgb(42, 228, 60);
  color: rgb(0, 0, 0);
  font-size: 0.67em;
  border-radius: 35px;
  height: 40px;
  border: none;
}

.datas {
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 12px;
  gap: 4px;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.163);
}

.datas img {
  width: 18px;
}

input {
  width: 100%;
  border: none;
  outline: none;
  height: 55px;
  color: white;
  background: rgba(255, 255, 255, 0);
  font-size: 0.8em;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.292);
  font-size: 0.8em;
}

.aboutusx {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  /* background-color: white; */
  overflow: hidden;
}

.aboutusx img:nth-child(1) {
  width: 660px;
  height: auto;
}

.aboutusx img:nth-child(2) {
  width: 660px;
  height: auto;
  margin-left: -60px;
}

.aboutusx img:nth-child(3) {
  width: 880px;
  height: 400px;
  margin-left: -154px;
}

.aboutus {
  width: 92%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 63px;
  /* background-color: white; */
  overflow: hidden;
}

.aboutus img:nth-child(1) {
  width: 880px;
  height: 400px;
}

.aboutus img:nth-child(2) {
  width: 880px;
  height: 400px;
  margin-left: -94px;
}

.aboutus img:nth-child(3) {
  width: 880px;
  height: 400px;
  margin-left: -154px;
}

.aboutuso {
  width: 92%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 63px;
  /* background-color: white; */
  overflow: hidden;
}

.aboutuso img:nth-child(1) {
  width: 670px;
  height: auto;
}

.aboutuso img:nth-child(2) {
  width: 670px;
  height: auto;
  margin-left: -34px;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.059),
    rgba(0, 0, 0, 1)
  );
}

.overlayx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.543),
    rgba(0, 0, 0, 1)
  );
}

.overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.059),
    rgba(0, 0, 0, 0.299)
  );
}

.aboutuso img:nth-child(3) {
  width: 670px;
  height: auto;
  margin-left: -154px;
}

.scroll-image {
  animation: scrollLeft 20s linear infinite;
}

.onrow {
  position: absolute;
  width: auto;
  top: 17%;
  left: 6%;
  height: 90px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  backdrop-filter: blur(2px);
  box-shadow:
    inset 0 10px 55px rgba(7, 248, 200, 0),
    0 25px 8px rgb(0, 0, 0, 0.05),
    0 20px 15px rgb(0, 0, 0, 0.05),
    inset 0 -10px 15px rgb(0, 0, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.086);
  /* background-color: rgba(255, 255, 255, 0.035); */
}
.onrow .scroll-image {
  width: 610px;
  height: 200px;
}

.spears {
  width: 90%;
  height: 40px;
  position: absolute;
  z-index: 333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: -109%;
  background: rgba(34, 243, 166, 0.211);
  border-top: 1px solid rgb(2, 127, 121);
  border-bottom: 1px solid rgb(2, 127, 121);
left: 8px;
  /* border-radius: 8px; */
}

.spears p {
  color: rgb(3, 215, 162);
  font-size: 0.75em;
  font-weight: 333;
}

.spearsx {
  width: 90%;
  height: 40px;
  position: absolute;
  z-index: 333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: -109%;
  background: rgba(0, 6, 4, 0.031);
 
left: 8px;
  /* border-radius: 8px; */
}

.spearsx p {
  color: rgba(87, 249, 171, 0.735);
  font-size: 0.75em;
  font-weight: 333;
}

.tworow {
  width: 90%;
  min-height: 30vh;
  /* background-color: white; */
  margin-top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 32px;
  margin-left: 33px;
}

.tworow .blankimg {
}

#cardds {
  border-radius: 50%;
  /* padding: 12px; */
  background: rgba(0, 213, 163, 0.098);
  position: absolute;
  top: -47px;
  left: 86px;
  border-bottom: 1px solid rgb(97, 230, 97);
  z-index: 12;
  width: 40px;
  height: 40px;
  padding: 8px;
  backdrop-filter: blur(22px);
  /* box-shadow:
  inset 0 10px 55px rgba(239, 255, 249, 0.046),
  0 25px 8px rgb(0, 0, 0, 0.05),
  0 20px 35px rgba(139, 255, 216, 0.284),
  inset 0 -10px 15px rgb(0, 0, 0, 0.05); */
}

.boxxing::before {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: -15px;
  border-radius: 53% 47% 49% 51% / 61% 59% 41% 39%;
  background: rgba(0, 255, 136, 0.028);
  rotate: 45deg;
  animation: animate_01 1s linear infinite;
  border: 1px solid rgb(0, 151, 144);
  z-index: 344;
  /* bottom: 20px; */
  box-shadow:
    inset 0 10px 55px rgba(6, 241, 147, 0.046),
    0 25px 8px rgb(0, 0, 0, 0.05),
    0 20px 15px rgb(0, 0, 0, 0.05),
    inset 0 -10px 15px rgba(5, 217, 175, 0.262);
  margin-top: 44px;
}

.boxxings::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  border-radius: 15px;
  background: rgba(0, 255, 136, 0.028);
  rotate: 45deg;
  animation: animate_01 12s linear infinite;
  border: 1px solid rgb(0, 151, 144);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 344;
  margin-top: 24px;
}

#arrowsx {
  position: absolute;
}

@keyframes animate_01 {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes animate_02 {
  100% {
    rotate: 0deg;
  }

  0% {
    rotate: 360deg;
  }
}

.boxxings::after {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 15px;
  background: rgba(0, 136, 255, 0.04);
  border: 1px solid rgb(84, 243, 121);
  rotate: 45deg;
  animation: animate_02 12s linear infinite;
  backdrop-filter: blur(22px);
  margin-left: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 344;
  margin-top: 24px;
}
.boxxing::after {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 59% 41% 49% 51% / 44% 49% 51% 56%;
  background: rgba(0, 136, 255, 0.04);
  border: 1px solid rgba(80, 254, 220, 0.413);
  rotate: 45deg;
  animation: animate_02 1s linear infinite;
  backdrop-filter: blur(22px);
  margin-left: -20px;
  z-index: 344;
  margin-top: 40px;
}

.cardone {
  position: relative;
  width: 35%;
  min-height: 25vh;
  background: rgba(255, 255, 255, 0.045);
  border-radius: 8px;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba(20, 251, 255, 0.126);
  box-shadow:
    inset 0 10px 55px rgba(6, 241, 147, 0.046),
    0 25px 8px rgb(0, 0, 0, 0.05),
    0 20px 15px rgb(0, 0, 0, 0.05),
    inset 0 -10px 15px rgb(0, 0, 0, 0.05);
  padding: 5px;
  z-index: -1;
}

.cardone::before {
  content: "";
  position: absolute;
  top: 0;
  left: 4px;
  width: 96.5%;
  height: 98%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.908),
    rgba(0, 0, 0, 0.721),
    rgba(0, 0, 0, 0)
  );
  z-index: 1;
  border-radius: 8px;
}

.eventi {
  position: absolute;
  width: 560px;
  height: 200px;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.lower {
  width: 100%;
  min-height: 50vh;
  /* background-color: rgba(255, 255, 255, 0.064); */
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: row;
  padding: 13px 93px;
  margin-top: 9%;
}

.aboutleft {
  width: 46%;
  height: auto;
  margin-top: -34px;
}

.first {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -34px;
}

.first p {
  font-size: 1.6em;
  font-weight: 600;
  color: white;
  line-height: 28px;
  letter-spacing: 0.1px;
}

.impacts {
  width: 650px;
}

.upperx {
  width: 93%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: -53px;
  position: relative;
  z-index: 90000;
  background-color: #88888825;
  padding-top: 23px;
  padding-bottom: 23px;
}

.sects {
  height: 24px;
  display: flex;
  cursor: pointer;
}

.sects:nth-child(1) {
  height: 24px;
  color: rgb(0, 229, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.653);
}

.sects:nth-child(1) P {
  height: 24px;
  color: rgb(0, 229, 0);
  border-bottom: 1px solid rgba(112, 253, 4, 0.653);
}

.sects p {
  color: rgba(255, 255, 255, 0.648);
  font-size: 0.68em;
}

.middsx {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 23px;
  /* background-color: red; */
  gap: 90px;
  /* padding-left: 20%; */
  padding: 0 4%;
  margin-top: 12px;
}

.concpt {
  width: auto;
  border-bottom: 2px solid rgba(255, 255, 255, 0.614);
  margin-top: 53px;
}

.concpt img {
  width: 280px;
}

.concptt {
  width: auto;
  border-bottom: 2px solid rgba(255, 255, 255, 0.614);
  margin-top: 53px;
}

.concptt img {
  width: 220px;
}

.concptr {
  width: auto;
  border-bottom: 2px solid rgba(255, 255, 255, 0.614);
  margin-top: 53px;
}

.concptr img {
  width: 370px;
}

.concpte {
  width: 100%;
  border-left: 2px solid rgba(255, 255, 255, 0.084);
  border-bottom: 2px solid rgba(255, 255, 255, 0.084);
}

.concpteu img {
  width: 310px;
}

.concpteu {
  width: 100%;
  border-left: 2px solid rgba(255, 255, 255, 0.084);
  border-bottom: 2px solid rgba(255, 255, 255, 0.084);
  background-color: rgba(255, 255, 255, 0.042);
  margin-bottom: 33px;
}

.concpte img {
  width: 310px;
}

.concptei {
  padding-left: 23px;
  width: 100%;
  border-left: 2px solid rgba(255, 255, 255, 0.084);
  border-bottom: 2px solid rgba(255, 255, 255, 0.084);
  background-color: rgba(255, 255, 255, 0.042);
}

.concptei img {
  width: 230px;
}

.concpteii {
  display: flex;
  align-items: center;

  padding-left: 23px;
  width: 100%;
  border-left: 2px solid rgba(255, 255, 255, 0.084);
  border-bottom: 2px solid rgba(255, 255, 255, 0.084);
  background-color: rgba(255, 255, 255, 0.042);
  margin-bottom: 34px;
}

.concpteii img {
  width: 280px;
}
.concpteiis {
  padding-left: 23px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 2px solid rgba(255, 255, 255, 0.084);
  /* border-bottom: 2px solid rgba(255, 255, 255, 0.084); */
  background-color: rgba(255, 255, 255, 0.042);
  margin-bottom: 34px;
}

.concpteiis img {
  width: 190px;
}
.concword {
  color: rgba(255, 255, 255, 0.802);
  font-size: 0.7em;
  width: 70%;
  text-align: center;
  margin-top: 64px;
}
.concword p {
  color: rgb(55, 255, 0);
  font-size: 0.7em;
  width: 70%;
  text-align: center;
}
.aboutdesc {
  width: 100%;
  height: auto;
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.borderabout {
  width: 23%;
  background-color: rgba(255, 255, 255, 0.46);
  height: 3px;
}

.aboutdata {
  width: 100%;
  margin-right: 13px;
}

.aboutdata p {
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.286);
  margin-left: 12px;
  margin-top: -5px;
  font-weight: 100;
}

.aboutright {
  width: 50%;
  /* background-color: rgba(255, 255, 255, 0.081); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.imageone {
  position: absolute;
  width: 65%;
  height: 36vh;
  border-radius: 5px;
  z-index: 23;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-top: -15%;
  margin-left: -12%;
  background: linear-gradient(0deg, rgb(149, 248, 167), rgb(0, 183, 255));
  background-size: 200% 200%;
  animation: smoothRotate 5s linear infinite;
}

@keyframes smoothRotate {
  0% {
    background: linear-gradient(0deg, rgb(149, 248, 167), rgb(0, 255, 145));
  }
  100% {
    background: linear-gradient(360deg, rgb(116, 218, 111), rgb(128, 128, 129));
  }
}

.imagetwo {
  position: absolute;
  width: 48%;
  height: 35vh;
  background-color: rgba(122, 255, 228, 0.423);
  border-radius: 5px;
  right: -42px;
  top: -154px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.imagethree {
  position: absolute;
  width: 31%;
  height: 16vh;
  background-color: rgba(8, 237, 134, 0.191);
  border-radius: 12px;
  left: -12px;
  top: -72px;
  z-index: 77;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  flex-direction: column;
  backdrop-filter: blur(14px);
  border: 2px solid rgb(12, 250, 71);
  
}
#ayear {
  width: auto;
  margin-top: 0;
}
.imagethree p  {
 
  color: rgb(25, 255, 4);
  font-size: 0.8em;
}

 

.imageone img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.imagetwo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.imagethree img {
  width: 50px;
  height: 50px;
  
}

/* MORAN SMART CENTER - MIN TOUR */

.smartcenter {
  width: 90%;
  min-height: 100vh;
  /* background-color: rgb(244, 253, 245); */
  margin-top: 64px;
  padding: 27px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  border: 2px solid rgb(102, 244, 0);
  box-shadow:
  inset 0 10px 55px rgba(35, 217, 7, 0.414),
  0 25px 68px rgba(15, 207, 18, 0.308),
  0 20px 15px rgb(0, 0, 0, 0.05),
  inset 0 -10px 15px rgba(2, 226, 1, 0.05);
}

.tour {
  width: 100%;
  height: 90vh;
  /* background-color: rgb(0, 12, 2); */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.centervideo {
  position: absolute;
  width: 96%;
  height: 93%;
  object-fit: cover;
  /* transform: translate(-50%, -50%); */
}

.smartxcenter {
  position: absolute;
  width: 35%;
  border-top: 1px solid rgb(98, 237, 98);
  min-height: 90px;
  background-color: rgba(0, 0, 0, 0.525);
  backdrop-filter: blur(4px);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 25px;
  bottom: 32px;
  border-radius: 5px;
}

.smartxcenter p:nth-child(1) {
  color: rgb(98, 255, 50);
  font-size: 1.5em;
  font-weight: bold;
  /* font-family: 'Mina'; */
}

.smartxcenter p:nth-child(2) {
  color: rgb(199, 199, 199);
  font-size: 0.8em;
  /* font-weight: bold; */
  /* font-family: 'Mina'; */
}

/* mouse tracking code */

.mousepointer {
  position: fixed;
  width: 90px;
  height: 90px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s ease-out;
  transform: translate(-50%, -50%);
  z-index: 90000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 253, 21, 0.952);
  backdrop-filter: blur(3px);
}

.midcircle {
  width: 20px;
  height: 20px;
  background-color: rgb(169, 253, 1);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s ease-out;
}

.mousepointers {
  position: fixed;
  width: 99px;
  height: 99px;
  background-color: rgba(0, 253, 21, 0);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s ease-out;
  transform: translate(-50%, -50%);
  z-index: 90000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 253, 21, 0.952);
  backdrop-filter: blur(3px);
}

.midcircles {
  width: 20px;
  height: 20px;
  background-color: rgb(169, 253, 1);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s ease-out;
}

/* years - about page */
.audiocontrol {
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 32px;
  background-color: rgba(2, 246, 140, 0.341);
  border: 2px solid white;
}

.audiocontrol img {
  width: 19px;
}
.years {
  width: 100%;
  height: auto;
  /* background-color: rgb(255, 255, 255); */
  margin-top: 64px;
  padding: 27px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.lowersectx {
  width: 100%;
  flex-direction: column;
  padding: 53px;
  position: relative;
  display: flex;
  align-items: center;
}

.concptdets {
  width: 100%;
  min-height: 20vh;
  /* background-color: rgb(253, 255, 254); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.picsbox {
  width: 25%;
  min-height: 83vh;
  background-color: rgb(0, 0, 0);
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-left: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.picsboxx {
  width: 25%;
  min-height: 48vh;
  background-color: rgb(0, 0, 0);
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-left: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.crc::before {
  content: "";
  position: absolute;
  right: 14px;
  bottom: -230px;
  width: 200px;
  height: 200px;
  /* transform: rotate(90deg); */
  border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
  /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
  background: linear-gradient(#20ff070b, #013e098a);
  transform-style: preserve-3d;
  transform: translateZ(90px);
  backdrop-filter: blur(3px);
  animation: animate_01 12s linear infinite;
  pointer-events: none;
  border-left: 2px solid rgba(98, 255, 36, 0.193);
  border-right: 1px solid rgba(255, 255, 255, 0.717);
  border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
  /* background: rgb(163, 14, 255); */
  z-index: -1;
}

.crc::after {
  content: "";
  position: absolute;
  left: -100px;
  top: 80px;
  width: 200px;
  height: 200px;
  /* transform: rotate(90deg); */
  border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
  /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
  background: linear-gradient(#13ff0723, #012a0809);
  transform-style: preserve-3d;
  transform: translateZ(90px);
  backdrop-filter: blur(3px);
  animation: animate_01 12s linear infinite;
  pointer-events: none;
  border-left: 2px solid rgba(36, 255, 175, 0.447);
  border-right: 3px solid rgba(10, 230, 17, 0.233);
  border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
  /* background: rgb(163, 14, 255); */
  z-index: -1;
}
.crcs::after {
  content: "";
  position: absolute;
  right: -100px;
  top: 80px;
  width: 200px;
  height: 200px;
  /* transform: rotate(90deg); */
  border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
  /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
  background: linear-gradient(#13ff0723, #012a0809);
  transform-style: preserve-3d;
  transform: translateZ(90px);
  backdrop-filter: blur(3px);
  animation: animate_01 12s linear infinite;
  pointer-events: none;
  border-left: 2px solid rgba(36, 255, 175, 0.447);
  border-right: 3px solid rgba(10, 230, 17, 0.233);
  border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
  /* background: rgb(163, 14, 255); */
  z-index: -1;
}

@keyframes animate_01 {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}
.midbox {
  position: absolute;
  width: 46%;
  min-height: 86vh;
  margin-top: 10.3%;
  background-color: rgb(0, 152, 53);
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-left: 3px solid black;
  z-index: 9099;
}

.moreabout {
  width: 20%;
  height: 7vh;
  background-color: rgba(255, 255, 255, 0.056);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(217, 247, 192, 0.704);
  border-radius: 45px;
  margin-top: 45px;
}

.moreabout p {
  color: rgb(15, 215, 91);
  font-size: 0.9em;
}

.augpics {
  width: 100%;
  height: 56vh;
  /* background-color: rgba(183, 183, 183, 0.056); */
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-left: 3px solid black;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* ------------------- */
.picsbox {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.081);
}

.picsboxx {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.picsbox:nth-child(2) {
  border: 1px solid rgba(94, 254, 94, 0.119);
  border-bottom: none;
}

.picsbox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.picsboxx img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.picsbox {
  width: 100%;
  height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picsbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.409),
    rgba(0, 0, 0, 0)
  );
  z-index: 1;
}

.picsboxx::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.723),
    rgba(0, 0, 0, 0)
  );
  z-index: 1;
}

.picsbox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 0;
}

/* ---------------- */
.picone {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.081);
}

.picone:nth-child(2) {
  border: 1px solid rgba(94, 254, 94, 0.119);
  border-bottom: none;
}

.picone img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picone {
  width: 100%;
  height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picone::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.723),
    rgba(0, 0, 0, 0)
  );
  z-index: 1;
}

.picone img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

/* --------------------- */
.piconex {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.081);
}

.piconex:nth-child(2) {
  border: 1px solid rgba(94, 254, 94, 0.119);
  border-bottom: none;
}

.piconex img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.piconex {
  width: 100%;
  height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.piconex::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.723),
    rgba(0, 0, 0, 0)
  );
  z-index: 1;
}

.piconex img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.vrr {
  position: absolute;
  bottom: 2px;
  z-index: 2332;
}

.vrr img {
  width: 230px;
}
.vrra {
  position: absolute;
  bottom: 2px;
  z-index: 2332;
}

.vrra img {
  width: 240px;
}

.animvid {
  position: absolute;
  width: 25%;
  height: 100%;
  right: 10%;
  top: 53px;
  object-fit: cover;
  /* transform: translate(-50%, -50%); */
  z-index: -1;
}
.animvids {
  position: absolute;
  width: 25%;
  height: 100%;
  right: 10%;
  bottom: 53px;
  object-fit: cover;
  /* transform: translate(-50%, -50%); */
  z-index: -1;
}

.yrsintox {
  width: 0%;
  color: rgb(255, 255, 255);
  font-size: 1.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 93px;
  position: absolute;
  opacity: 0;
}

.yrsinto {
  width: 40%;
  color: rgb(255, 255, 255);
  font-size: 1.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 93px;
  opacity: 1;
}

.yrsinto img {
  width: 140%;
}

.yrevent img {
  width: 100%;
  margin-left: -72px;
  margin-bottom: 12px;
}

.yrevents img {
  width: 100%;
  /* margin-left: -72px; */
  margin-bottom: 112px;
  position: absolute;
}

.yearone {
  width: 100%;
  /* min-height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: red; */
  /* margin-bottom: 50px; */
}

.yeartwo {
  margin-top: 84px;
  width: 100%;
  /* min-height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: red; */
  /* margin-bottom: 50px; */
  /* animation-timeline: scroll(); */
}

.pup {
  position: absolute;
  width: 90px;
  height: 90px;
  background-color: rgba(0, 253, 21, 0);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s ease-out;
  transform: translate(-50%, -50%);
  z-index: 90000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 253, 21, 0.64);
  backdrop-filter: blur(3px);
  bottom: -135px;
}

.ccc {
  width: 20px;
  height: 20px;
  background-color: rgba(169, 253, 1, 0.792);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s ease-out;
}

.yrno {
  width: 50%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: blue; */
}

.yearintersect {
  width: 1px;
  margin-left: 113px;
  margin-right: 113px;
  height: 74vh;
  background-color: rgba(2, 255, 2, 0.538);
  position: relative;
}

.square {
  width: 23px;
  height: 23px;
  background-color: rgb(102, 255, 0);
  rotate: 45deg;
  position: absolute;
  left: -11px;
  top: 45%;
}

.yrno p {
  color: white;
}

.yrnos {
  width: 50%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: blue; */
  opacity: 1;
}

.yrnosx {
  width: 5%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: blue; */
  opacity: 0;
}

#expertise {
  color: rgb(6, 255, 6);
}

.yrnos p {
  color: white;
}
.yrevent {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.yrevent p {
  color: white;
  margin-bottom: 20px;
  font-size: 1.8em;
  font-weight: bold;
}

.yrevents {
  margin-top: -12%;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.yrnosx {
  opacity: 0;
  position: absolute;
}

.yrevents p {
  color: white;
  margin-bottom: 20px;
  font-size: 1.8em;
  font-weight: bold;
  text-align: justify;
  /* position: absolute; */
  left: 44px;
  /* top: -73px; */
}

.yrevents span {
  width: 480px;
  position: absolute;
  color: rgba(255, 255, 255, 0.552);
  left: 44px;
  top: 73px;
  font-size: 0.74em;
  text-align: start;
  word-spacing: 0.01px;
}

.yrevent span {
  color: rgba(255, 255, 255, 0.552);
  font-size: 0.74em;
  text-align: start;
}

.numbervid {
  width: 120%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0%;
  margin-bottom: -64px;
}
.numbervidy {
  width: 160%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20%;
}
/* about lower sect */

.detailsnos {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.imgdet {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
}

.imgdet img {
  width: 15px;
}

.imgdets {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  margin-top: 23px;
  margin-bottom: 23px;
}

.imgdets img {
  width: 15px;
}

.imgdets p {
  font-size: 0.6em;
}

.detailsnos p {
  color: white;
  font-size: 0.7rem;
  margin-bottom: 0;
}
.lowersect {
  width: 100%;
  height: 90px;
  /* background-color: rgb(255, 255, 255); */
  margin-top: 214px;
  padding: 27px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  border: 1px solid rgba(255, 255, 255, 0.145);
  backdrop-filter: blur(3px);
  gap: 12px;
}

.imc {
  color: rgba(255, 255, 255, 0.644);
  font-size: 1em;
}

.linetrr {
  width: 70%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.145);
  margin: 10px 0;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.socs {
  background-color: rgb(40, 243, 40);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socs img {
  width: 28px;
  height: 28px;
}

/* Experiential marketing */

.contentsx {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  min-height: 50vh;
  display: flex;
  margin-top: 90px;
  align-items: center;
  justify-content: center;

  flex-direction: row;
}
.directarrow {
  width: 80%;
  min-height: 30vh;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.directarrow img {
  width: 50px;
  height: 50px;
}
.videosall {
  width: 43%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  gap: 60px;
  flex-direction: column;
  /* background: rgba(255, 255, 255, 0.15); */
  margin-right: 12px;
  padding: 23px;
}

.comb {
  width: 100%;
  height: auto;
  padding: 14px;
  top: -20px;
  right: -20px;
  background-color: rgba(255, 255, 255, 0.052);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(85, 255, 28, 0.723);
  border-top: 1px solid rgba(69, 252, 8, 0.369);
  backdrop-filter: blur(3px);
}
.crcxs::after {
  content: "";
  position: absolute;
  right: -100px;
  top: 80px;
  width: 200px;
  height: 200px;
  /* transform: rotate(90deg); */
  border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
  /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
  background: linear-gradient(#07ffc913, #012a0809);
  transform-style: preserve-3d;
  transform: translateZ(90px);
  backdrop-filter: blur(3px);
  animation: animate_01 12s linear infinite;
  pointer-events: none;
  border-left: 1px solid rgba(36, 255, 175, 0.284);
  border-right: 1px solid rgba(164, 255, 206, 0.233);
  border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
  /* background: rgb(163, 14, 255); */
  z-index: -1;
}
.vidonesx {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35vh;
  background-color: rgba(255, 255, 255, 0.039);
  /* border-radius: 5px; */
  backdrop-filter: blur(3px);

  z-index: 23;

  /* border: 20px solid rgba(62, 205, 0, 0); */
  /* border-bottom: 33px solid rgba(255, 255, 255, 0.024); */
  /* overflow: hidden; */
  flex: 0 0 28%; /* Prevent shrinking and growing, fixed width */
}

.vidones {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35vh;
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 5px;
  backdrop-filter: blur(3px);
  position: relative;
  z-index: -1;
  /* overflow: hidden; */
  animation: slide 11s linear infinite;
  flex: 0 0 28%; /* Prevent shrinking and growing, fixed width */
}
.vidoness {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 95%;
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 5px;
  backdrop-filter: blur(3px);
  position: relative;
  overflow: hidden;

  flex: 0 0 28%; /* Prevent shrinking and growing, fixed width */
}

@keyframes slide {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.centervideoxy {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* transition: transform 0.3s ease; */
}

.aboutwd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: rgba(255, 255, 255, 0.037); */
  border-radius: 5px;
  /* backdrop-filter: blur(3px); */
  padding: 20px;
  position: relative;
  margin-top: -94px;
  margin-right: -42px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.157);
}

.aboutwd p {
  font-size: 0.7em;
  color: #ffffff9e;
  text-align: center;
}

.clss {
  width: 200px;
}

.centervideox {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease;
}
.centervideoxss {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}
.vidones::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.668),
    rgba(0, 0, 0, 0.592)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  /* border-top: 5px solid rgba(247, 247, 247, 0.666); */
}

.vidones:hover::after {
  opacity: 1;
}

.vidones .hover-text {
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidones:hover .hover-text {
  opacity: 1;
}

.arrodir {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(85, 226, 97, 0.062);
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid rgb(7, 198, 68); */
  backdrop-filter: blur(3px);
  bottom: -44%;
  margin-left: -19px;
  z-index: 34;
}

.arrodirx {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 253, 21, 0.062);
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid rgb(1, 244, 1);
  backdrop-filter: blur(3px);
  bottom: 20px;
  opacity: 0;
}

.arrodir img {
  width: 20px;
  height: 20px;
}

.arrodirx img {
  width: 28px;
  height: 28px;
}
.readmore {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 253, 21, 0.062);
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid rgb(1, 244, 1);
  backdrop-filter: blur(3px);
  bottom: -20px;
}
.morehere {
  position: absolute;
  width: 150px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.392);
  border-radius: 54px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(254, 254, 254);
  backdrop-filter: blur(3px);
  bottom: 15px;
  color: rgb(66, 64, 64);
  font-size: 0.8em;
}

/* MUltimedia devices */

.multimediapage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.03);
}

.devices {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.devices canvas {
  width: 100%;
  height: 100vh;
  display: block;
}

.hubs {
  position: absolute;
  bottom: 40px;
  display: flex;
  gap: 10px;
  width: 60%;
  height: 70px;
  background-color: rgba(128, 255, 17, 0.119);
  border-radius: 40px;
  backdrop-filter: blur(3px);
  border: 2px solid rgb(92, 255, 47);
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  opacity: 1;
  z-index: 9990909;
}

.comimg {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.comimgs {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  /* margin-left: 23px; */
}

.frsimg {
  width: 70%;
}

.frsimgs {
  width: 120%;
}

.secimg {
  width: 90%;
  position: absolute;
  left: 33%;
}
.secimgs {
  width: 90%;
  position: absolute;
  left: 31%;
}

.thridimg {
  width: 100%;
  position: absolute;
  left: 70%;
}

/* pointers in devices */

.onpointers {
  position: absolute;
  top: -180%;
  display: flex;
  gap: 2px;
  width: 36px;
  height: 36px;
  border: 3px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.pointers {
  position: absolute;
  display: flex;
  gap: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.pointers1 {
  position: absolute;
  display: flex;
  top: 30%;
  left: 22%;
  gap: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.pointers2 {
  position: absolute;
  display: flex;
  top: 34%;
  left: 32%;
  gap: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.pointers3 {
  position: absolute;
  display: flex;
  top: 30%;
  right: 52%;
  gap: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.pointers4 {
  position: absolute;
  display: flex;
  top: 28%;
  right: 32%;
  gap: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.pointers5 {
  position: absolute;
  display: flex;
  top: 20%;
  right: 12%;
  gap: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.pointers6 {
  position: absolute;
  left: 12%;
  display: flex;
  gap: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(15, 250, 15, 0.147);
}

.nexts {
  position: absolute;
  bottom: 12%;
  right: 2%;
  display: flex;
  width: 130px;
  height: 60px;
  border: 1px solid rgba(140, 253, 122, 0.116);
  border-radius: 45px;
  background-color: rgba(116, 116, 116, 0.068);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(13px);
  gap: 12px;
}

.nexts img {
  width: 25px;
  height: 25px;
}

.nexts p {
  font-size: 1.2rem;
  color: rgb(0, 248, 41);
  font-weight: bold;
}

.prevs {
  position: absolute;
  bottom: 12%;
  left: 2%;
  display: flex;
  width: 130px;
  height: 60px;
  border: 1px solid rgba(140, 253, 122, 0.116);
  border-radius: 45px;
  background-color: rgba(116, 116, 116, 0.068);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(13px);
  gap: 12px;
}

.prevs img {
  width: 25px;
  height: 25px;
}

.prevs p {
  font-size: 1.2rem;
  color: rgb(0, 248, 41);
  font-weight: bold;
}

/* hubs descs */

.hubsnames {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
  width: 24%;
  height: 56px;
  background-color: rgba(17, 255, 33, 0.119);
  border-radius: 40px;
  backdrop-filter: blur(3px);
  border: 2px solid rgb(47, 255, 134);
  align-items: center;
  justify-content: space-evenly;
}

.comimgx {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.collect {
  width: 190%;
}

.hubsnamesx {
  position: absolute;
  bottom: 140px;
  display: flex;
  gap: 10px;
  width: 30%;
  height: 70px;
  /* background-color: rgba(128, 255, 17, 0.119); */
  border-radius: 40px;
  /* backdrop-filter: blur(3px); */
  /* border: 2px solid rgb(92, 255, 47); */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.nameone {
  width: 200%;
  position: absolute;
  margin-bottom: 42%;
  margin-left: 6%;
}

.nameonei {
  width: 200%;
  position: absolute;
  margin-bottom: 42%;
  margin-left: 6%;
}

.nametwo {
  position: absolute;
  width: 130%;
  margin-bottom: 84px;
  /* margin-left: -52%; */
}

.nametwoe {
  position: absolute;
  width: 170%;
  bottom: 4px;
  /* margin-left: -52%; */
}

.nametwou {
  position: absolute;
  width: 210%;
  margin-bottom: 84px;
  /* margin-left: -52%; */
}
.nametwoa {
  position: absolute;
  width: 180%;
  margin-bottom: 84px;
  /* margin-left: -52%; */
}
.nametwoex {
  position: absolute;
  width: 170%;
  margin-bottom: 34px;
  /* margin-left: -52%; */
}
.namethree {
  position: absolute;
  width: 200%;
  margin-left: 82%;
}
.namethreet {
  position: absolute;
  width: 200%;
  margin-left: 92%;
}
.nametwox {
  position: absolute;
  width: 200%;
  margin-left: -65%;
}
.namethreex {
  position: absolute;
  width: 200%;
  margin-left: 80%;
}

.nametwoxa {
  position: absolute;
  width: 200%;
  margin-left: -95%;
}
.namethreexa {
  position: absolute;
  width: 200%;
  margin-left: 80%;
}

.hubsnamesxio {
  position: absolute;
  bottom: 240px;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  /* background-color: rgba(128, 255, 17, 0.119); */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgb(0, 0, 0),
    rgb(0, 0, 0)
  );
  /* border-radius: 40px; */
  /* backdrop-filter: blur(3px); */
  /* border: 2px solid rgb(92, 255, 47); */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hubsnmx {
  position: absolute;
  bottom: 50px;
  display: flex;
  gap: 10px;
  width: 30%;
  height: 70px;
  /* background-color: rgba(128, 255, 17, 0.119); */
  border-radius: 40px;
  /* backdrop-filter: blur(3px); */
  /* border: 2px solid rgb(92, 255, 47); */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.namezeroy {
  width: 200%;
  position: absolute;
  margin-bottom: 1%;
  /* margin-left: 80%; */
}

.namezeroyx {
  width: 20%;
  position: absolute;
  margin-bottom: 1%;
  /* margin-left: 80%; */
  opacity: 0;
}

.nameoney {
  width: 200%;
  position: absolute;
  margin-bottom: 50%;
  margin-left: 70%;
}
.nametwoy {
  position: absolute;
  width: 200%;
  margin-left: -52%;
  margin-bottom: -16%;
}
.namethreey {
  position: absolute;
  width: 200%;
  margin-left: 128%;
  margin-bottom: -16%;
}

.nametwoxy {
  position: absolute;
  width: 200%;
}

.namethreexp {
  position: absolute;
  width: 200%;
  /* margin-left: 80%; */
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: -25%;
  text-align: center;
}

.nameoneq {
  width: 200%;
  position: absolute;
  margin-bottom: 42%;
}
.nametwoq {
  position: absolute;
  width: 200%;
  margin-left: -122%;
}
.namethreeq {
  position: absolute;
  width: 200%;
  margin-left: 112%;
}
.nametwoqa {
  position: absolute;
  width: 200%;
  margin-left: -52%;
}
.namethreeqa {
  position: absolute;
  width: 160%;
  margin-left: 132%;
}

/* pan through the model */

.pan {
  position: absolute;
  top: 22%;
  right: 2%;
  display: flex;
  width: 60px;
  height: 60px;
  border: 3px solid rgb(34, 255, 0);
  border-radius: 50%;
  background-color: rgba(138, 255, 13, 0.068);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(13px);
  gap: 12px;
}

.pan img {
  width: 30px;
  height: 30px;
}

/* CONTACT US */

.contactus {
  width: 90%;
  min-height: 89vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.301);
  position: absolute;
  /* backdrop-filter: blur(2px); */
  right: 5%;
  margin-top: 64px;
  z-index: 34;
  align-items: center;
  flex-direction: column;
}

.mapps {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  bottom: 2%;
  right: 0;
  border: 19px solid rgba(244, 248, 244, 0.107);
  /* animation: rotateCircle 90s linear infinite; */
}

.switchmap {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background: rgba(5, 141, 5, 0.244);
  border-radius: 50%;
  z-index: 2000;
  position: absolute;
  right: -20px;
  border: 3px solid rgb(123, 245, 147);
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchmap img {
  width: 30px;
  height: 30px;
}

.details {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32%;
}

.phonex {
  width: 300px;
  margin-top: 23%;
  margin-left: 15%;
  border: 1px solid rgba(255, 255, 255, 0.144);
  border-radius: 5px;
  padding: 12px;

  position: absolute;
}

.phonx {
  width: 40px;
  height: 40px;
  top: -45%;
  position: absolute;
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cirdivs {
  width: 50px;
  height: 50px;
  background-color: rgba(21, 255, 118, 0.073);
  border-radius: 50%;
  position: absolute;
  bottom: 27%;
  z-index: 34344;
  border: 1px solid rgb(17, 255, 203);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
}

.cirdivs img {
  width: 30px;
}

.cirdiv {
  width: 50px;
  height: 50px;
  background-color: rgba(21, 255, 118, 0.073);
  border-radius: 50%;
  position: absolute;
  bottom: 8.3%;
  z-index: 34344;
  border: 1px solid rgb(17, 255, 203);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
  padding: 8px;
}

.cirdiv img {
  width: auto;
}

.cirdivy {
  width: 50px;
  height: 50px;
  background-color: rgba(21, 255, 118, 0.073);
  border-radius: 50%;
  position: absolute;
  bottom: 28.3%;
  z-index: 34344;
  border: 1px solid rgb(17, 255, 203);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
  padding: 8px;
}

.cirdivy img {
  width: auto;
}

.cirdivx {
  width: 50px;
  height: 50px;
  background-color: rgba(21, 255, 118, 0.073);
  border-radius: 50%;
  position: absolute;
  bottom: 23.3%;
  z-index: 34344;
  border: 1px solid rgb(17, 255, 203);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
  padding: 8px;
}

.cirdivx img {
  width: auto;
}

/* Mobile view */

@media (max-width: 998px) {
  .mousepointer {
    position: fixed;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 253, 21, 0);
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.1s ease-out;
    transform: translate(-50%, -50%);
    z-index: 90000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(0, 253, 21, 0.952);
    backdrop-filter: blur(3px);
  }

  .menuimg {
    /* cursor: pointer; */
    width: 25px;
  }
  .allmenu {
    width: 340px;
    min-height: 20vh;
    position: absolute;
    left: 5px;
    top: 55px;
    padding: 29px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-left: 5px solid rgb(64, 255, 35);
    border-right: 1px solid rgba(38, 253, 0, 0.159);
    border-top: 1px solid rgba(38, 253, 0, 0.168);
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.563); */
    background-color: rgb(0, 0, 0);
    transition:
      opacity 2s ease-out,
      transform 0.5s ease-out;
    transform: translateY(-10px);
    overflow: hidden;
    z-index: 90000;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .allmenux {
    width: 340px;
    min-height: 20vh;
    position: absolute;
    left: 5px;
    top: 55px;
    padding: 29px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-left: 5px solid rgb(64, 255, 35);
    border-right: 1px solid rgba(38, 253, 0, 0.159);
    border-top: 1px solid rgba(38, 253, 0, 0.168);
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.563); */
    background-color: rgb(0, 0, 0);
    transition:
      opacity 2s ease-out,
      transform 0.5s ease-out;
    transform: translateY(-10px);
    overflow: hidden;
    z-index: 90000;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lineerases {
    position: absolute;
    width: 22px;
    height: 194px;
    top: 0px;
    opacity: 0;
    right: 0px;
    border-right: 1px solid rgba(76, 254, 0, 0.273);
    background-color: rgba(0, 0, 0, 0);
  }

  .morehere {
    position: relative;
    width: 100px;
    height: 45px;
    background-color: rgba(203, 203, 203, 0.23);
    border-radius: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(216, 253, 220, 0.826);
    backdrop-filter: blur(3px);
    bottom: 5px;
    color: rgb(49, 102, 54);
    font-size: 0.8em;
    font-weight: bold;
  }

  .arrodirx {
    position: relative;
    width: 35px;
    height: 35px;
    background-color: rgb(0, 253, 21);
    border-radius: 50%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgba(1, 244, 1, 0);
    backdrop-filter: blur(3px);
    opacity: 1;
    margin-top: 42px;
  }

  .arrodirx img {
    width: 28px;
    height: 28px;
  }

  .midcircle {
    width: 10px;
    height: 10px;
    background-color: rgb(169, 253, 1);
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.1s ease-out;
  }

  .videosall {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    gap: 60px;
    overflow: auto;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    margin-right: 0;
    /* background-color: red; */
    margin-top: 85px;
  }

  .vidones {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 95%;
    background-color: rgba(255, 255, 255, 0.025);
    border-radius: 24px;
    backdrop-filter: blur(3px);
    position: relative;
    overflow: none;
    animation: none;
    flex: 0 0 95%;
    margin-left: 14px;
  }
  .vidoness {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 95%;
    background-color: rgba(255, 255, 255, 0.025);
    border-radius: 4px;
    backdrop-filter: blur(3px);
    position: relative;
    overflow: none;
    animation: none;
    flex: 0 0 95%;
    margin-left: 14px;
  }
  .welcomes {
    width: 100%;
    /* background-color: white; */
    position: relative;
    display: flex;
    flex-direction: column;
    top: 55%;
  }

  .learnbtn {
    position: relative;
    width: 10px;
    height: 80px;
    opacity: 0;
  }

  .welcomes .toinno {
    width: 2%;
    /* height: 370px; */
    opacity: 0;
    position: absolute;
  }

  .welco {
    width: 52%;
    /* height: 370px; */
    visibility: 1;
    opacity: 1;
    position: relative;
  }
  .learnbtns {
    position: relative;
    top: 12px;
    width: 220px;
    height: 55px;
    border-radius: 0px;
    border: none;
    background: rgba(21, 213, 220, 0);
    border-top: 1px solid rgb(9, 243, 200);
    color: rgb(166, 255, 234);
    margin-top: -24px;
    
  }
  .lowerdiv {
    position: absolute;
    opacity: 1;
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(3px);
    border-top-left-radius: 294px;
    border-top-right-radius: 294px;
    bottom: 0;
    box-shadow:
      inset 0 10px 55px rgba(115, 241, 96, 0.089),
      0 25px 8px rgb(0, 0, 0, 0.05),
      0 20px 15px rgb(0, 0, 0, 0.05),
      inset 0 -10px 15px rgb(0, 0, 0, 0.05);
    border-top: 1px solid rgba(47, 244, 47, 0.382);
    animation: slideUp 0.8s ease-out forwards;
  }

  .lowerdivx {
    position: absolute;
    opacity: 1;
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(3px);
    border-top-left-radius: 294px;
    border-top-right-radius: 294px;
    bottom: 0;
    gap: 12px;
    flex-direction: column;
    border-top: 1px solid rgba(56, 255, 56, 0.133);
    animation: slideUp 0.8s ease-out forwards;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .dotted {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    margin-bottom: -44px;
    border-radius: 50%;
    animation: moveAlongEdge 5s linear infinite;
  }

  @keyframes moveAlongEdge {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(calc(100% - 10px), 0);
    }
    50% {
      transform: translate(calc(100% - 10px), -45vh);
    }
    75% {
      transform: translate(0, -45vh);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .aboutus {
    width: 92%;
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 63px;
    /* background-color: white; */
    overflow: hidden;
  }

  .aboutus img:nth-child(1) {
    width: 500px;
    height: auto;
  }

  .aboutus img:nth-child(2) {
    width: 500px;
    height: auto;
    margin-left: -14px;
  }
  .aboutleft {
    width: 100%;
    height: auto;
    margin-top: -34%;
    display: flex;
    flex-direction: column;
    /* background-color: white; */
  }

  .first {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 23px;
    margin-top: -23%;
  }

  .first p {
    font-size: 0.9em;
    color: white;

    font-weight: bold;
    text-align: center;
    word-spacing: -0.1px;
    line-height: 19px;
  }

  .impacts {
    width: 450px;
  }
  .aboutdesc {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .aboutdata {
    width: 100%;
    margin-right: 13px;
  }

  .aboutdata p {
    font-size: 0.65em;
    color: rgba(255, 255, 255, 0.286);
    margin-left: 12px;
    margin-top: -5px;
    font-weight: 100;
  }
  .lower {
    width: 100%;
    min-height: 50vh;
    /* background-color: rgba(255, 255, 255, 0.064); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 0;
    margin-top: 4%;
  }
  .yearone {
    width: 100%;
    /* min-height: 90vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    align-items: center;
    flex-direction: column;
    animation-timeline: scroll();
  }

  .yeartwo {
    margin-top: 84px;
    width: 100%;
    /* min-height: 90vh; */
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: rgb(255, 255, 255); */
    /* margin-bottom: 50px; */
    animation-timeline: scroll();
  }

  .yrevent {
    /* background-color: red;; */
    width: 87%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
  }

  .yrevents {
    margin-top: 23px;
    width: 87%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #ayear {
    margin-top: 34px;
  }

  .yrevent p {
    color: white;
    margin-bottom: 20px;
    font-size: 1em;
    font-weight: bold;
    text-align: justify;
    /* position: absolute; */
    left: 0;
    /* top: -73px; */
  }

  .yrevent img {
    width: 100%;
    margin-left: -0;
    margin-top: 30px;
  }

  #rrvnt {
    margin-top: -52px;
  }

  #rrvnts {
    margin-top: -30px;
  }

  .yrevents img {
    width: 100%;
    /* margin-left: -72px; */
    margin-bottom: 3px;
    position: relative;
    bottom: -50px;
  }
  .yrevents p {
    color: white;
    margin-bottom: 20px;
    font-size: 1em;
    font-weight: bold;
    text-align: justify;
    /* position: absolute; */
    left: 0;
    /* top: -73px; */
  }

  .yrevents span {
    color: rgba(255, 255, 255, 0.673);
    left: 0;
    top: 103px;
    font-size: 0.67em;
    text-align: justify;
    display: block;
    text-align: center;
    text-justify: inter-word;
    word-spacing: -0.1em;
    width: auto;
  }

  .yrevent span {
    color: rgba(255, 255, 255, 0.662);
    font-size: 0.68em;
    display: block;
    text-align: center;
    text-justify: inter-word;
    word-spacing: -0.1em;
  }

  .numbervid {
    width: 193%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .numbervidy {
    width: 130px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    position: absolute;
  }
  .years {
    width: 100%;
    height: auto;
    /* background-color: rgb(255, 255, 255); */
    margin-top: 64px;
    padding: 27px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  .animvid {
    position: absolute;
    width: 200px;
    height: 100%;
    right: 0;
    top: 53px;
    object-fit: cover;
    /* transform: translate(-50%, -50%); */
    z-index: -1;
  }
  .yrsinto {
    width: 9%;
    color: rgb(255, 255, 255);
    font-size: 1.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: absolute;
    opacity: 0;
  }

  .yrsinto img {
    position: absolute;
    width: 2%;
    opacity: 0;
  }

  .yrsintox {
    width: auto;
    color: rgb(255, 255, 255);
    font-size: 1.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: relative;
    opacity: 1;
    /* background-color: #fff; */
  }
  .yrsintox img {
    width: 190%;
  }

  .pup {
    position: absolute;
    width: 90px;
    height: 90px;
    background-color: rgba(0, 253, 21, 0);
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.1s ease-out;
    transform: translate(-50%, -50%);
    z-index: 90000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(0, 253, 21, 0.64);
    backdrop-filter: blur(3px);
    bottom: -0;
  }
  .square {
    width: 23px;
    height: 23px;
    background-color: rgb(102, 255, 0);
    rotate: 45deg;
    position: absolute;
    left: -11px;
    top: 45%;
    opacity: 0;
  }

  .yearintersect {
    width: 1px;
    margin-left: 113px;
    margin-right: 113px;
    height: 0;
    background-color: rgba(2, 255, 2, 0.538);
    position: absolute;
  }

  .pup {
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: rgba(0, 253, 21, 0);
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.1s ease-out;
    transform: translate(-50%, -50%);
    z-index: 90000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(0, 253, 21, 0.64);
    backdrop-filter: blur(3px);
    bottom: -135px;
    opacity: 0;
  }

  .animvid {
    position: absolute;
    width: 2px;
    height: 100%;
    right: 0;
    top: 53px;
    object-fit: cover;
    /* transform: translate(-50%, -50%); */
    z-index: -1;
    opacity: 0;
  }
  .lowersect {
    width: 100%;
    height: 50px;
    /* background-color: rgb(255, 255, 255); */
    margin-top: 214px;
    padding: 27px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    border: 1px solid rgba(255, 255, 255, 0.145);
    backdrop-filter: blur(3px);
    gap: 12px;
    border-left: 5px solid rgb(20, 240, 20);
  }

  .imc {
    color: rgba(255, 255, 255, 0.481);
    font-size: 0.5em;
  }

  .linetrr {
    width: 40%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.145);
    margin: 10px 0;
  }

  .socs {
    background-color: rgb(40, 243, 40);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .socs img {
    width: 18px;
    height: 18px;
  }

  .smartxcenter {
    position: absolute;
    width: 90%;
    border-top: 1px solid rgba(98, 237, 98, 0);
    min-height: 60px;
    background-color: rgba(255, 255, 255, 0.049);
    backdrop-filter: blur(4px);
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 22px;
    bottom: -70px;
    border-radius: 5px;

    border-bottom: 1px solid rgb(98, 237, 98);
  }

  .smartxcenter p:nth-child(1) {
    color: rgb(91, 247, 43);
    font-size: 1em;
    font-weight: bold;
    font-family: "Mina";
  }

  .smartxcenter p:nth-child(2) {
    color: rgb(199, 199, 199);
    font-size: 0.7em;
    /* font-weight: bold; */
    /* font-family: 'Mina'; */
  }

  p {
    cursor: default;
  }

  span {
    cursor: default;
  }

  .mousepointers {
    position: fixed;
    width: 49px;
    height: 49px;
    background-color: rgba(0, 253, 21, 0);
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.1s ease-out;
    transform: translate(-50%, -50%);
    z-index: 90000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(0, 253, 21, 0.952);
    backdrop-filter: blur(3px);
  }

  .midcircles {
    width: 20px;
    height: 20px;
    background-color: rgb(169, 253, 1);
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.1s ease-out;
  }
  .pan {
    position: absolute;
    top: 17%;
    right: 5%;
    display: flex;
    width: 40px;
    height: 40px;
    border: 2px solid rgb(99, 240, 77);
    border-radius: 50%;
    background-color: rgba(138, 255, 13, 0.068);
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(13px);
    gap: 12px;
  }

  .pan img {
    width: 20px;
    height: 20px;
  }

  .namezeroy {
    width: 2%;
    position: absolute;
    margin-bottom: 1%;
    /* margin-left: 80%; */
    opacity: 0;
  }

  .namezeroyx {
    width: 250%;
    position: absolute;
    bottom: 21%;
    /* margin-left: 80%; */
    opacity: 1;
  }

  .namezeroyxs {
    width: 280%;
    position: absolute;
    bottom: 21%;
    /* margin-left: 80%; */
    opacity: 1;
  }
  .hubs {
    position: absolute;
    bottom: 40px;
    display: flex;
    gap: 10px;
    width: 1%;
    height: 70px;
    background-color: rgba(128, 255, 17, 0.119);
    border-radius: 40px;
    backdrop-filter: blur(3px);
    border: 2px solid rgb(92, 255, 47);
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
  }

  .dotted img {
    width: 33px;
  }

  .sectdon {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    top: 12%;
  }

  .sectdon .imgx {
    width: 43px;
    background: rgba(77, 255, 0, 0.186);
    border-radius: 50%;
    padding: 10px;
    position: absolute;
    /* right: -17px; */
    bottom: 60px;
    border-bottom: 1px solid rgb(77, 255, 0);
  }

  .sectdon .exp {
    width: 153px;
  }

  .sectdon .imgxx {
    width: 133px;
    position: absolute;
    bottom: -60px;
  }

  .dotcc {
    position: absolute;
    left: 1%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgb(134, 255, 5);
  }

  .dotccx {
    position: absolute;
    right: 1%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.216);
  }

  .dotccs {
    position: absolute;
    left: -3%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgb(134, 255, 5);
  }

  .dotccxs {
    position: absolute;
    right: -3%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.216);
  }

  .hubsnames {
    position: absolute;
    bottom: 20px;
    display: flex;
    gap: 10px;
    width: 24%;
    height: 56px;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(17, 255, 33, 0.119);
    border-radius: 40px;
    backdrop-filter: blur(3px);
    border: 2px solid rgb(47, 255, 134);
    align-items: center;
    justify-content: space-evenly;
  }

  .nexts {
    position: absolute;
    bottom: 12%;
    right: 2%;
    display: flex;
    width: 100px;
    height: 50px;
    border: 1px solid rgba(140, 253, 122, 0.116);
    border-radius: 45px;
    background-color: rgba(116, 116, 116, 0.068);
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(13px);
    gap: 12px;
  }

  .nexts img {
    width: 16px;
    height: 16px;
  }

  .nexts p {
    font-size: 0.7rem;
    color: rgb(0, 248, 41);
    font-weight: bold;
  }

  .prevs {
    position: absolute;
    bottom: 12%;
    left: 2%;
    display: flex;
    width: 100px;
    height: 50px;
    border: 1px solid rgba(140, 253, 122, 0.116);
    border-radius: 45px;
    background-color: rgba(116, 116, 116, 0.068);
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(13px);
    gap: 12px;
  }

  .prevs img {
    width: 16px;
    height: 16px;
  }

  .prevs p {
    font-size: 0.7rem;
    color: rgb(0, 248, 41);
    font-weight: bold;
  }

  .namethreexp {
    position: absolute;
    width: 200%;
    /* margin-left: 80%; */
    color: white;
    font-weight: 555;
    font-size: 0.7rem;
    margin-bottom: -25%;
    text-align: center;
  }
  .nametwo {
    position: absolute;
    width: 230%;
    bottom: 21%;
    /* margin-left: -52%; */
  }

  .nametwoa {
    position: absolute;
    width: 300%;
    margin-bottom: 84px;
    /* margin-left: -52%; */
  }

  .nametwoe {
    position: absolute;
    width: 300%;
    margin-bottom: 84px;
    /* margin-left: -52%; */
  }

  .onpointers {
    position: absolute;
    top: -230%;
    display: flex;
    gap: 2px;
    width: 24px;
    height: 24px;
    border: 3px solid rgb(34, 255, 0);
    border-radius: 50%;
    background-color: rgba(15, 250, 15, 0.147);
  }

  .nametwoex {
    position: absolute;
    width: 250%;
    margin-bottom: 34px;
    /* margin-left: -52%; */
  }

  .contactus {
    width: 100%;
    min-height: 89vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.486);
    position: relative;
    backdrop-filter: blur(1.8px);
    right: 0;
    margin-top: 64px;
    z-index: 34;
    align-items: center;
    flex-direction: column;
  }
  .innovationsx {
    width: 300px;
    position: absolute;
    top: 10%;
    left: 7.6%;
    opacity: 0;
    visibility: none;
  }

  .innovationsxa {
    width: 300px;
    position: absolute;
    bottom: 2%;
    right: -7%;
    z-index: 1;
    opacity: 0;
    visibility: none;
  }

  .mapps {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    margin-bottom: -73%;
    backdrop-filter: blur(21px);
    top: 20%;
    right: 53px;
    border: 19px solid rgba(244, 248, 244, 0.107);
    /* animation: rotateCircle 90s linear infinite; */
  }

  .detailsnos {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .imgdet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 9px;
  }

  .imgdet img {
    width: 15px;
  }

  .imgdets {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    margin-top: 23px;
    margin-bottom: 23px;
  }

  .imgdets img {
    width: 15px;
  }

  .imgdets p {
    font-size: 0.6em;
  }

  .detailsnos p {
    color: white;
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  .phonx {
    width: 40px;
    height: 40px;
    top: -44%;
    position: absolute;
  }

  .details {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -43%;
  }
  .smartcenter {
    width: 98%;
    min-height: 40vh;
    background-color: rgba(255, 255, 255, 0.133);
    margin-top: 64px;
    padding: 7px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60%;
    border-radius: 6px;
    border: 1px solid rgba(102, 244, 0, 0.867);

    box-shadow:
    inset 0 10px 55px rgba(7, 217, 108, 0.089),
    0 25px 68px rgba(243, 243, 243, 0.028),
    0 20px 15px rgb(0, 0, 0, 0.05),
    inset 0 -10px 15px rgba(2, 226, 1, 0.05);
  }

  .centervideo {
    position: absolute;
    width: 96%;
    height: 93%;
    object-fit: cover;
    border-radius: 6px;
    /* transform: translate(-50%, -50%); */
  }

  .audiocontrol {
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 32px;
    background-color: rgba(2, 246, 140, 0.341);
    border: 2px solid white;
  }

  .audiocontrol img {
    width: 19px;
  }

  .yrnosx {
    width: 50%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: blue; */
    opacity: 1;
    margin-top: -23%;
  }

  .yrnos {
    width: 50%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: blue; */
    opacity: 0;
  }
  #aauea {
    margin: 3px;
    position: absolute;
    margin-bottom: -25%;
  }

  .aboutdata {
    width: 100%;
    margin-right: 13px;
    margin-top: 185px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aboutdata p {
    width: 80%;
    font-size: 0.85em;
    color: rgba(255, 255, 255, 0.895);
    margin-left: 12px;
    margin-top: 65px;
    font-weight: 100;
    text-align: center;
    text-justify: inter-word;
    text-align-last: center;
    word-spacing: 0.11;
  }

  .aboutright {
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0.081); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 100px;
  }

  .imageone {
    position: relative;
    width: 95%;
    height: 40vh;
    border-radius: 5px;
    z-index: 23;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    margin-top: 0px;
    margin-left: 0px;
    margin: 9px;
    background: linear-gradient(0deg, rgb(149, 248, 167), rgb(0, 183, 255));
    background-size: 200% 200%;
    animation: smoothRotate 5s linear infinite;
  }

  @keyframes smoothRotate {
    0% {
      background: linear-gradient(0deg, rgb(149, 248, 167), rgb(0, 255, 145));
    }
    100% {
      background: linear-gradient(
        360deg,
        rgb(116, 218, 111),
        rgb(128, 128, 129)
      );
    }
  }

  .imagetwo {
    position: absolute;
    width: 48%;
    height: 35vh;
    background-color: rgba(122, 255, 228, 0.423);
    border-radius: 25px;
    right: 0;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    opacity: 0;
    visibility: hidden;
  }

  .imagetwo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
  }

  .imagethree {
    position: absolute;
    width: 31%;
    height: 16vh;
    background-color: rgba(8, 237, 134, 0.191);
    border-radius: 12px;
    left: 10px;
    top: -72px;
    z-index: 77;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    flex-direction: column;
    backdrop-filter: blur(14px);
    border: 2px solid rgb(12, 250, 71);
  }

  .imagethree p  {

    color: rgb(25, 255, 4);
    font-size: 0.7em;
  }
  

  .allpages {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .haveacool {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: relative;
    min-height: 10vh;
    padding: 23px;
  }

  .coolimg {
    width: 270px;
    height: auto;
    position: absolute;
    top: 24%;
    border-bottom: 2px solid rgba(255, 255, 255, 0.438);
  }

  .allincool {
    width: 100%;
    height: auto;
    margin-top: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .contaxts {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 65vh;
    border: 1px solid rgba(255, 255, 255, 0.295);
    padding: 43px;
    border-radius: 7px;
    backdrop-filter: blur(20px);
    position: relative;
    margin-top: 39%;
  }

  .aboutusx {
    width: 90%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    /* background-color: white; */
    overflow: hidden;
  }

  .aboutusx img:nth-child(1) {
    width: 360px;
    height: auto;
  }

  .aboutusx img:nth-child(2) {
    width: 280px;
    height: auto;
    margin-left: -40px;
  }

  .getint {
    width: 45%;
    margin-left: -34px;
    border-radius: 35px;
    height: 50px;
    position: absolute;
    bottom: 23px;
    left: 75px;
  }

  .getintx {
    width: 100%;
    background-color: rgb(6, 220, 70);
    color: rgb(0, 0, 0);
    border-radius: 35px;
    height: 50px;
    border: none;
  }
  .switchmap {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: rgba(5, 141, 5, 0.244);
    border-radius: 50%;
    z-index: 2000;
    position: absolute;
    left: -70px;
    bottom: -34px;
    border: 3px solid rgb(123, 245, 147);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .switchmap img {
    width: 30px;
    height: 30px;
  }
  .aboutuso {
    width: 92%;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 63px;
    /* background-color: white; */
    overflow: hidden;
  }

  .aboutuso img:nth-child(1) {
    width: 470px;
    height: auto;
  }

  .aboutuso img:nth-child(2) {
    width: 470px;
    height: auto;
    margin-left: -34px;
  }

  .aboutuso img:nth-child(3) {
    width: 670px;
    height: auto;
    margin-left: -154px;
  }

  .middsx {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    /* background-color: red; */
    gap: 90px;
    /* padding-left: 20%; */
    padding: 0 0;
    flex-direction: column;
  }

  .concptt {
    margin-top: 23px;
    margin-bottom: -30px;
    width: auto;
    border-bottom: 2px solid rgba(255, 255, 255, 0.614);
  }

  .concptt img {
    width: 220px;
  }

  .concpt {
    margin-top: -23px;
    margin-bottom: 45px;
    width: auto;
    border-bottom: 2px solid rgba(255, 255, 255, 0.614);
  }

  .concpte {
    width: auto;
    border: none;
  }

  .concpt img {
    width: 220px;
  }

  .concpte img {
    width: 260px;
  }
  .upperx {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: #88888800 transparent;
    padding: 10px;
    width: 88%;
    margin-bottom: -5px;
  }

  .upperx::-webkit-scrollbar {
    height: 6px;
  }

  .upperx::-webkit-scrollbar-thumb {
    background: #888;
    /* border-radius: 10px; */
  }

  .sects {
    flex: 0 0 auto;
    padding: 10px 20px;
    /* background-color: #f0f0f0; */
    margin-right: 10px;
    border-radius: 8px;
  }

  .sects p {
  }
  .hubsnamesxio {
    position: absolute;
    bottom: 300px;
    display: flex;
    gap: 10px;
    width: 100%;
    height: 100%;
    /* background-color: rgba(128, 255, 17, 0.119); */
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgb(0, 0, 0),
      rgb(0, 0, 0)
    );
    /* border-radius: 40px; */
    /* backdrop-filter: blur(3px); */
    /* border: 2px solid rgb(92, 255, 47); */
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .lowersectx {
    width: 98%;
    flex-direction: column;
    padding: 5px;
    position: relative;
    display: flex;
    align-items: center;
    height: auto;

  }

  .dotsted {
    width: 100%;
    min-height: 57vh;
  }

  .midbox {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }
  .concptdets {
    width: 100%;
    min-height: 20vh;
    /* background-color: rgb(253, 255, 254); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .picsbox {
    width: 99%;
    min-height: 43vh;
    background-color: rgb(0, 0, 0);
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    border-left: 3px solid black;
  }

  .augpics {
    width: 100%;
    height: 56vh;
    background-color: rgba(183, 183, 183, 0.056);
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    border-left: 3px solid black;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
  }

  .picone {
    border: 1px solid black;
    min-height: 100%;
    margin-top: 33px;
  }

  .picone:nth-child(2) {
    border-top: 1px solid rgba(255, 255, 255, 0.179);
    background-color: black;
    padding: 0;
  }

  .picone:nth-child(3) {
    border-top: 1px solid rgba(255, 255, 255, 0.179);
    background-color: black;
    padding: 0;
  }

  .piconex {
    border: 1px solid black;
    min-height: 100%;
  }

  .piconex:nth-child(2) {
    border-top: 1px solid rgba(255, 255, 255, 0.179);
    background-color: black;
    padding: 0;
  }

  .piconex:nth-child(3) {
    border-top: 1px solid rgba(255, 255, 255, 0.179);
    background-color: black;
    padding: 0;
  }

  /* ----------------------- */
  .vrr {
    position: absolute;
    bottom: 2px;
    z-index: 2332;
  }

  .vrr img {
    width: 210px;
  }
  .vrra {
    position: absolute;
    bottom: 2px;
    z-index: 2332;
  }

  .vrra img {
    width: 180px;
  }
  .upperx {
    padding: 23px;
    padding-bottom: 34px;
    background-color: rgba(221, 226, 222, 0.123);
    overflow-y: hidden;
    border-top: 1px solid rgba(226, 227, 226, 0.262);
    border-bottom: 1px solid rgba(226, 227, 226, 0.262);
  }
  .sects:nth-child(1) {
    height: 24px;
    color: rgb(0, 229, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0);
  }
  .sects:nth-child(1) p {
    height: 24px;
    color: rgb(90, 248, 90);
    border-bottom: 1px solid rgba(255, 255, 255, 0);
  }
  .concpteii {
    display: flex;
    align-items: center;

    padding-left: 5px;
    width: 100%;
    border-left: 2px solid rgba(255, 255, 255, 0.084);
    border-bottom: 2px solid rgba(255, 255, 255, 0.084);
    background-color: rgba(255, 255, 255, 0.042);
    margin-bottom: 34px;
  }
  .concpteu {
    width: 100%;
    border-left: 2px solid rgba(255, 255, 255, 0.084);
    border-bottom: 2px solid rgba(255, 255, 255, 0.084);
    background-color: rgba(255, 255, 255, 0.042);
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    margin-top: 70px;
    border-top: 1px solid rgba(184, 255, 62, 0.149);
  }

  .ismobile {
    width: 100%;
    /* background-color: white; */
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34px;
    flex-direction: column;
    padding: 12px;
    padding-bottom: 34px;
  }

  .mobileone {
    width: 95%;
    height: 62vh;
    border-radius: 8px;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -54px;
  }

  .mobileintro {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 184px;
    height: 1vh;
  }

  .mobileintro img {
    width: 530px;
  }

  .mobilenumb {
    width: 300px;
  }

  .mobiledesc {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 34vh;
    justify-content: center;
    margin-top: 36px;
    padding: 12px;
    backdrop-filter: blur(20px);
    border-top: 2px solid rgb(45, 250, 93);
    background-color: rgba(0, 0, 0, 0.062);
    position: relative;
    z-index: 100;
    box-shadow:
      inset 0 10px 75px rgba(4, 234, 177, 0.093),
      0 25px 8px rgb(0, 0, 0, 0.05),
      0 20px 15px rgb(0, 0, 0, 0.05),
      inset 0 -10px 15px rgb(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.384);
  }

  .mobileword {
    width: 100%;
    height: 8vh;
    margin-top: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobiledesc span {
    color: rgb(252, 252, 252);
    font-size: 0.73em;
    font-weight: 100;
    text-align: center;
  }

  .crc::before {
    content: "";
    position: absolute;
    right: 104px;
    top: 30px;
    width: 200px;
    height: 200px;
    /* transform: rotate(90deg); */
    border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
    /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
    background: linear-gradient(#07ffda00, #06ff3809);
    transform-style: preserve-3d;
    transform: translateZ(90px);
    backdrop-filter: blur(3px);
    animation: animate_01 12s linear infinite;
    pointer-events: none;
    border-left: 2px solid rgba(46, 122, 5, 0.193);
    border-right: 3px solid rgba(61, 254, 51, 0.717);
    border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
    /* background: rgb(163, 14, 255); */
    z-index: -1;
  }

  .crc::after {
    content: "";
    position: absolute;
    left: -100px;
    top: 80px;
    width: 200px;
    height: 200px;
    /* transform: rotate(90deg); */
    border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
    /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
    background: linear-gradient(#13ff0723, #012a0809);
    transform-style: preserve-3d;
    transform: translateZ(90px);
    backdrop-filter: blur(3px);
    animation: animate_01 12s linear infinite;
    pointer-events: none;
    border-left: 2px solid rgba(36, 255, 175, 0.447);
    border-right: 3px solid rgba(10, 230, 17, 0.233);
    border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
    /* background: rgb(163, 14, 255); */
    z-index: -1;
  }
  .crcs::after {
    content: "";
    position: absolute;
    right: -100px;
    top: 80px;
    width: 200px;
    height: 200px;
    /* transform: rotate(90deg); */
    border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
    /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
    background: linear-gradient(#13ff0723, #012a0809);
    transform-style: preserve-3d;
    transform: translateZ(90px);
    backdrop-filter: blur(3px);
    animation: animate_01 12s linear infinite;
    pointer-events: none;
    border-left: 2px solid rgba(36, 255, 175, 0.447);
    border-right: 3px solid rgba(10, 230, 17, 0.233);
    border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
    /* background: rgb(163, 14, 255); */
    z-index: -1;
  }

  .crcss::before {
    content: "";
    position: absolute;
    left: -100px;
    top: 80px;
    width: 200px;
    height: 200px;
    /* transform: rotate(90deg); */
    border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
    /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
    background: linear-gradient(#13ff0723, #08f93009);
    transform-style: preserve-3d;
    transform: translateZ(90px);
    backdrop-filter: blur(3px);
    animation: animate_01 12s linear infinite;
    pointer-events: none;
    border-left: 2px solid rgba(36, 255, 175, 0.447);
    border-right: 1px solid rgba(10, 230, 17, 0.233);
    border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
    /* background: rgb(163, 14, 255); */
    z-index: -1;
  }

  .crcsx::after {
    content: "";
    position: absolute;
    left: -100px;
    top: 80px;
    width: 200px;
    height: 200px;
    /* transform: rotate(90deg); */
    border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
    /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
    background: linear-gradient(#12ebb90f, #012a0809);
    transform-style: preserve-3d;
    transform: translateZ(90px);
    backdrop-filter: blur(3px);
    animation: animate_01 12s linear infinite;
    pointer-events: none;
    border-left: 2px solid rgba(66, 237, 186, 0.657);
    border-right: 1px solid rgba(10, 230, 17, 0.233);
    border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
    /* background: rgb(163, 14, 255); */
    z-index: -1;
  }

  .crcsxs::after {
    content: "";
    position: absolute;
    bottom: -15%;
    left: 93px;
    width: 200px;
    height: 200px;
    /* transform: rotate(90deg); */
    border-radius: 53% 47% 46% 54% / 39% 39% 61% 61%;
    /* border-radius:64% 36% 39% 61% / 52% 54% 46% 48%  ; */
    background: linear-gradient(#12ebb818, #012a0809);
    transform-style: preserve-3d;
    transform: translateZ(90px);
    backdrop-filter: blur(3px);
    animation: animate_01 12s linear infinite;
    pointer-events: none;
    border-left: 2px solid rgba(66, 237, 186, 0);
    border-right: 1px solid rgba(10, 230, 193, 0);
    border-radius: 63% 37% 44% 56% / 49% 56% 44% 51%;
    /* background: rgb(163, 14, 255); */
    z-index: -1;
  }

  @keyframes animate_01 {
    0% {
      rotate: 0deg;
    }

    100% {
      rotate: 360deg;
    }
  }

  .concword {
    color: rgba(255, 255, 255, 0.802);
    font-size: 0.7em;
    width: 81%;
    text-align: center;
    margin-top: 4px;
  }
  .menuxs:active {
    background-color: transparent !important;
  }

  .menuxs:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .menuxs {
    -webkit-tap-highlight-color: transparent;
  }

  .tour {
    width: 100%;
    height: 40vh;
    /* background-color: rgb(0, 12, 2); */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .moreabout {
    width: 40%;
    height: 5vh;
    background-color: rgba(255, 255, 255, 0.056);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(217, 247, 192, 0.704);
    border-radius: 45px;
    margin-top: 45px;
  }

  .moreabout p {
    color: rgb(15, 215, 91);
    font-size: 0.9em;
  }

  .contentsx {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    min-height: 50vh;
    display: flex;
    margin-top: 90px;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }

  .tworow {
    width: 100%;
    min-height: 30vh;
    /* background-color: white; */
    margin-top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-left: 0px;
    flex-direction: column;
    overflow: none;
    position: relative;
  }

  .cardone {
    position: relative;
    width: 100%;
    min-height: 25vh;
    background: rgba(255, 255, 255, 0.045);
    border-radius: 8px;
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgba(20, 251, 255, 0.126);
    box-shadow:
      inset 0 10px 55px rgba(6, 241, 147, 0.046),
      0 25px 8px rgb(0, 0, 0, 0.05),
      0 20px 15px rgb(0, 0, 0, 0.05),
      inset 0 -10px 15px rgb(0, 0, 0, 0.05);
    padding: 5px;
    z-index: 1000;
  }

  .cardone::before {
    content: "";
    position: absolute;
    top: 0;
    left: 4px;
    width: 100%;
    height: 98%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.908),
      rgba(0, 0, 0, 0.721),
      rgba(0, 0, 0, 0)
    );
    z-index: 1;
    border-radius: 8px;
    
  }

  #cardds {
    border-radius: 50%;
    /* padding: 12px; */
    background: rgba(0, 213, 163, 0.048);
    position: absolute;
    top: 27px;
    left: 23px;
    border-bottom: 1px solid rgb(126, 252, 126);
    z-index: 12;
    width: 40px;
    height: 40px;
    padding: 8px;
    backdrop-filter: blur(22px);
    /* box-shadow:
  inset 0 10px 55px rgba(239, 255, 249, 0.046),
  0 25px 8px rgb(0, 0, 0, 0.05),
  0 20px 35px rgba(139, 255, 216, 0.284),
  inset 0 -10px 15px rgb(0, 0, 0, 0.05); */
  }

  .directarrow {
    width: 94%;
    min-height: 30vh;
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .onrow {
    position: absolute;
    width: auto;
    top: 6%;
    left: 6%;
    height: 90px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    backdrop-filter: blur(12px);
    box-shadow:
      inset 0 10px 55px rgba(7, 248, 200, 0),
      0 25px 8px rgb(0, 0, 0, 0.05),
      0 20px 15px rgb(0, 0, 0, 0.05),
      inset 0 -10px 15px rgb(0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    /* background-color: rgba(255, 255, 255, 0.035); */
  }
  .onrow .scroll-image {
    width: 500px;
    height: 150px;
  }
  .marketpage {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .menux p {
    color: white;
    font-size: 0.9em;
    letter-spacing: 0.9px;
    cursor: pointer;
  }
  .menuxs p {
    color: white;
    font-size: 0.9em;
    letter-spacing: 0.9px;
    cursor: pointer;
  }

  .comb {
    width: 100%;
    height: auto;
    padding: 1px;
    top: -2px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.052);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(85, 255, 28, 0.723);
    border-top: 1px solid rgba(69, 252, 8, 0.369);
    backdrop-filter: blur(3px);
  }


  
.spears {
  width: 90%;
  height: 48px;
  position: relative;
  z-index: 333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0px;
  background: rgba(34, 243, 166, 0.211);
  border-top: 1px solid rgb(2, 127, 121);
  border-bottom: 1px solid rgb(2, 127, 121);
left: 8px;
  /* border-radius: 8px; */
  backdrop-filter: blur(4px);
}

.spears p {
  color: rgb(3, 215, 162);
  font-size: 0.75em;
  font-weight: 333;
}

.spearsx {
  width: 90%;
  height: 40px;
  position: relative;
  z-index: 333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: -40px;
  background: rgba(0, 6, 4, 0.031);
 
left: 8px;
  /* border-radius: 8px; */
}

.spearsx p {
  color: rgba(255, 255, 255, 0.508);
  font-size: 0.6em;
  font-weight: 333;
  margin-left: -10px;
}
.checkspears{
  width: 90%;
  height: 60px;
  position: absolute;
  z-index: 333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0px;
  background: rgba(34, 243, 166, 0.211);
  left: 8px;
  /* border-radius: 8px; */
}

.aboutwd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: rgba(255, 255, 255, 0.037); */
  border-radius: 5px;
  /* backdrop-filter: blur(3px); */
  min-height: 30vh;
  padding: 20px;
  position: relative;
  margin-top: -94px;
  margin-right: 0px;
  margin-bottom: 42px;
  backdrop-filter: blur(1px);
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.aboutwd p {
  font-size: 0.7em;
  color: #ffffff9e;
  text-align: center;
}

.arrodiry {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: rgba(85, 226, 97, 0.062);
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid rgb(7, 198, 68); */
  backdrop-filter: blur(3px);
  bottom: -80%;
  margin-left: 0;
  z-index: 34;
  margin-bottom: 12px;
}

.arrodiry p{
  color: rgb(0, 255, 51);
  font-size:0.8em;
}
.centervideox {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
  transition: transform 0.3s ease;
}

.vidonesx {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35vh;
  background-color: rgba(255, 255, 255, 0.039);
  /* border-radius: 5px; */
  backdrop-filter: blur(3px);

  z-index: 23;

  /* border: 20px solid rgba(62, 205, 0, 0); */
  /* border-bottom: 33px solid rgba(255, 255, 255, 0.024); */
  /* overflow: hidden; */
  flex: 0 0 28%; /* Prevent shrinking and growing, fixed width */
}
}
